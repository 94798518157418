// Stars background

#star-background {
  height: 100vh;
  width: 100vw;
  background-image: radial-gradient(ellipse at bottom, $gradientColor 0%, #00000000 70%);
  overflow: hidden;
  position: absolute;
  z-index: -100;
}

// Creates $n number of box shadows for a div
@function multipleBoxShadow($n) {
  $value: '#{random() * 100}vw #{random() * 100}vh rgba(255, 255, 255, #{random()})';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random() * 100}vw #{random() * 100}vh rgba(255, 255, 255, #{random()})'
  }
  @return unquote($value)
}

// Number of stars for each layer
$shadowsSmall: multipleBoxShadow(300);
$shadowsMedium: multipleBoxShadow(100);
$shadowsLarge: multipleBoxShadow(50);

#stars1 {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadowsSmall;
  animation: animStar 150s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 100vh;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadowsSmall;
  }
}

#stars2 {
  width: 2px;
  height: 2px;
  border-radius: 1px;
  background: transparent;
  box-shadow: $shadowsMedium;
  animation: animStar 100s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 100vh;
    width: 2px;
    height: 2px;
    border-radius: 1px;
    background: transparent;
    box-shadow: $shadowsMedium;
  }
}

#stars3 {
  width: 3px;
  height: 3px;
  border-radius: 1.5px;
  background: transparent;
  box-shadow: $shadowsLarge;
  animation: animStar 50s linear infinite;

  &:after {
    content: " ";
    position: absolute;
    top: 100vh;
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background: transparent;
    box-shadow: $shadowsLarge;
  }
}

@keyframes animStar {
  from {
    transform: translateY(0vh);
  }
  to {
    transform: translateY(-100vh);
  }
}