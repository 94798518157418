body {
  background-color: $backgroundColor;
  color: $bodyColor;
  font-family: Inter, $bodyFontStack;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  body {
    font-family: InterVariable, $bodyFontStack;
  }
}

#content {
  position: absolute;
  z-index: 1;
  transition: opacity 300ms ease-in-out;
  height: 100vh;
}

// Button
.btn-outline-ml {
  @extend .btn;
  @extend .btn-outline-secondary;

  --#{$prefix}btn-color: #{$bodyColor};
  --#{$prefix}btn-hover-bg: #{$danger};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-active-bg: #{darken($danger, 10%)};
  --#{$prefix}btn-active-border-color: transparent;
}

.btn-no-outline-ml {
  @extend .btn-close;

  --#{$prefix}btn-close-color: #{$bodyColor};
  --#{$prefix}btn-close-hover-opacity: 100%;
  --#{$prefix}btn-close-focus-shadow: 0 0 0 0.25rem rgba($bodyColor, 0.25);

  background: none;
  filter: unset !important;
  font-size: x-large;
  transition: color $transitionNormal ease-in-out, opacity $transitionNormal ease-in-out;

  &:hover {
    --#{$prefix}btn-close-color: #{$danger};
  }

  &:active {
    --#{$prefix}btn-close-color: #{darken($danger, 10%)};
  }
}

// Forms
.form-control-ml {
  @extend .form-control;

  color: $bodyColor;
  background-color: rgba($bodyColor, 0.2);
  border-color: $secondary;

  &:focus {
    color: $bodyColor;
    background-color: rgba($bodyColor, 0.2);
  }

  &::placeholder {
    color: $bodyColor;
  }
}

// Animations
@keyframes fade-reveal {
  from {
    transform: translateY(.1em);
    opacity: 0;
  }
}

@keyframes fade-reveal-left {
  from {
    transform: translateX(-1em);
    opacity: 0;
  }
}

// Common
@import "stars-background";
@import "nav";
@import "fader";

// Launching Soon
@import "launching-soon";

// 404
@import "404";

// Home
@import "mattlabs-text";

// About
@import "about";

// Dev tag
@import "dev-tag";