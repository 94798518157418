// About

#about-container {
  height: calc(100vh - 70px);
  margin-top: 70px;
  animation: fade-reveal 1s 300ms ease-in-out backwards;
}

#about-cards {
  width: 100%;
  height: 100%;
}

.about-card {
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  position: relative;
  background-color: transparent;
  border: solid 1px transparent;
  opacity: 1;
  overflow: hidden;
  transition: background-color 500ms, border-color 500ms, opacity 499ms ease-in-out, min-height 500ms ease-in-out, max-height 500ms ease-in-out, margin 500ms ease-in-out, padding 500ms ease-in-out;
}

.about-card:hover {
  background-color: rgba(255, 255, 255, 0.02);
  border: solid 1px rgba(255, 255, 255, 0.05);
  transition: background-color 500ms, border-color 500ms;
}

.about-card:active {
  background-color: rgba(255, 255, 255, 0.05);
  transition: background-color $transitionNormal;
}

.about-card:hover::before {
  opacity: 1;
}

.about-card::before {
  content: "";
  background: radial-gradient(800px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.06), transparent 40%);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: opacity 500ms;
  position: absolute;
  z-index: -1;
  border-radius: inherit;
}

.about-card-hidden {
  opacity: 0;
  pointer-events: none;
  min-height: 0;
  max-height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.about-item-expand-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  transition: transform 350ms ease;
}

.about-item-expand-button-flip {
  transform: rotate(-180deg);
  transition: transform 350ms ease;
}

label.btn-outline-ml {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $black !important;
      background-color: $danger !important;
      border-color: transparent !important;
    }
  }

  &:active {
    color: $black !important;
    background-color: darken($danger, 10%) !important;
    border-color: transparent !important;
  }
}

input.btn-check:checked + label.btn-outline-ml {
  background-color: $danger;
}

.badge-about-tag {
  color: lighten($blueSpace, 45%) !important;
}

.btn-search-ml {
  @extend .btn;
  @extend .btn-outline-secondary;

  --#{$prefix}btn-bg: #{rgba($bodyColor, 0.2)};
  --#{$prefix}btn-color: #{$bodyColor};
  --#{$prefix}btn-hover-color: #{$danger};
  --#{$prefix}btn-hover-bg: #{rgba($bodyColor, 0.2)};
  --#{$prefix}btn-active-bg: #{rgba($bodyColor, 0.2)};
  --#{$prefix}btn-active-color: #{darken($danger, 10%)};
}

.input-group {
  @include border-radius($input-border-radius);

  &:focus-within {
    box-shadow: 0 0 0 0.25rem rgba($bodyColor, 0.2);

    & .search-bar {
      border-color: $bodyColor;
      box-shadow: none;
      transition: border-color $transitionNormal ease-in-out;
    }
  }
}

#filter-search-submit {
  min-width: 41px;
}

.about-card-col {
  min-height: 500px;
}

// Limit height at medium breakpoint
@include media-breakpoint-down(lg) {
  .about-card-col {
    max-height: 500px;
  }
}

#log-text {
  font-size: 12px !important;
  color: $bodyColor;
}

#log-error {
  color: $danger;
}

.nav-pills-ml {
  @extend .nav-pills;

  --#{$prefix}nav-pills-link-active-color: #{lighten($bodyColor, 10%)};
  --#{$prefix}nav-pills-link-active-bg: #{$lightSpace};

  & .nav-link {
    color: $bodyColor;
    padding: .25rem .5rem;
    font-size: $font-size-sm;
    border-radius: $border-radius-sm;

    &:hover {
      color: lighten($bodyColor, 10%);
    }

    & .active,
    .show > .nav-link {
      color: lighten($bodyColor, 10%);
    }
  }
}

#compile-button {
  min-width: 34px;
}

#pdfbox {
  position: relative;
  transition: opacity $transitionNormal ease-in-out;
  z-index: 5;
}

#pdf-compile-alert {
  position: absolute;
  top: 4em;
  opacity: 0.85;
  z-index: 5;
}

#pdf-pregen-alert {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.85;
  margin: 17px;
  z-index: 5;
}

.pdf-loading {
  opacity: 0;
}

#pdfbox-spinner {
  z-index: 4;
  position: absolute;
}

a {
  color: shift-color($bodyColor, -100%);
  text-decoration: none;
  transition: color $transitionNormal ease-in-out;

  &:hover,
  &:focus {
    color: $danger;
  }

  &:active {
    color: darken($danger, 10%);
  }
}